import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import 'src/utils/axios'

import 'antd/dist/antd.css'
import './index.css'
import { initializeApp } from 'firebase/app'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import getEnv from 'src/env'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'src/styles/theme'
import type {} from '@mui/lab/themeAugmentation'

initializeApp(getEnv()?.firebaseConfig)

import App from './App'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
