import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box } from 'src/styles/Layout'
import { Button, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import withPageBody from 'src/hoc/PageBody'

import { getMachinesApi } from 'src/api'
import { formatTimestamp } from 'src/utils/moment'
import LoadingOverlay from 'src/components/LoadingOverlay'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import IconButton from '@mui/material/IconButton'
import CreateMachineModal from './CreateMachineModal'
import { MachineType } from 'src/types/machine'
import ConfirmModal from 'src/components/ConfirmModal'
import { deleteMachineApi } from 'src/api/machine'

function PilotsPage() {
  const [showAddMachine, setShowAddMachine] = React.useState(false)
  const [machines, setMachines] = React.useState<MachineType[]>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [selectedMachine, setSelectedMachine] =
    React.useState<MachineType | null>(null)
  const [selectedMachineForDelete, setSelectedMachineForDelete] =
    React.useState<MachineType | null>(null)

  const getMachines = async () => {
    setIsLoading(true)

    try {
      const machines = await getMachinesApi()
      setMachines(machines.data.machines as MachineType[])
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteMachine = async () => {
    if (!selectedMachineForDelete) {
      return
    }

    setIsLoading(true)

    try {
      await deleteMachineApi(selectedMachineForDelete.id)
    } catch (err) {
      console.log(err)
    } finally {
      setSelectedMachineForDelete(null)
      setIsLoading(false)
      getMachines()
    }
  }

  React.useEffect(() => {
    getMachines()
  }, [])

  const onCloseAddModal = () => {
    setShowAddMachine(false)
    setSelectedMachine(null)
  }

  const openCreatePilotModal = () => {
    setSelectedMachine(null)
    setShowAddMachine(true)
  }

  const beforeCreate = () => {
    setIsLoading(true)
  }
  const afterCreate = () => {
    onCloseAddModal()
    getMachines()
  }

  const onEditMachine = (machine: MachineType) => () => {
    setSelectedMachine(machine)
    setShowAddMachine(true)
  }

  const onDeleteMachine = (machine: MachineType) => () => {
    setSelectedMachineForDelete(machine)
  }

  const closeMachineConfirmationModal = () => {
    setSelectedMachineForDelete(null)
  }

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <Box style={{ alignSelf: 'flex-end', marginTop: 20, marginBottom: 10 }}>
        <Button
          variant='outlined'
          startIcon={<Add />}
          onClick={openCreatePilotModal}
        >
          Add Machine
        </Button>
      </Box>
      <Box style={{ width: '100%' }}>
        <TableContainer component={Paper}>
          <Table aria-label='simple table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight='bold'>Call Sign</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight='bold'>Aircraft Model No</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight='bold'>Aircrat Type</Typography>
                </TableCell>
                <TableCell align='right'>
                  <Typography fontWeight='bold'>Created At</Typography>
                </TableCell>
                <TableCell align='right'>
                  <Typography fontWeight='bold'>Actions</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {machines.map(machine => (
                <TableRow
                  key={machine.callSign}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    <Typography>{machine.callSign}</Typography>
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    <Typography>{machine.modelNo}</Typography>
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    <Typography>{machine.type}</Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography>
                      {formatTimestamp(machine.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                      size='small'
                      onClick={onEditMachine(machine)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color='error'
                      aria-label='Delete Pilot'
                      component='span'
                      onClick={onDeleteMachine(machine)}
                      style={{ padding: 0 }}
                      title='Delete Pilot'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showAddMachine && (
          <CreateMachineModal
            onClose={onCloseAddModal}
            beforeCreate={beforeCreate}
            afterCreate={afterCreate}
            machineForEdit={selectedMachine}
          />
        )}
        <ConfirmModal
          open={!!selectedMachineForDelete}
          onClose={closeMachineConfirmationModal}
          onConfirm={deleteMachine}
          title='Delete Machine'
          description={`Are you sure you want to delete the pilot ${selectedMachineForDelete?.callSign} ?`}
        />
      </Box>
    </>
  )
}

export default withPageBody(PilotsPage)
