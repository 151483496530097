/* eslint-disable indent */
import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, Flex } from 'src/styles/Layout'
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { Download, Search } from '@mui/icons-material'
import withPageBody from 'src/hoc/PageBody'
import { getPilotsApi } from 'src/api'
import { PilotFdtlType, PilotType, PilotWithFdtlType } from 'src/types/pilot'
import { formatTimestamp } from 'src/utils/moment'
import LoadingOverlay from 'src/components/LoadingOverlay'
import DatePicker from '@mui/lab/DatePicker'
import { Moment } from 'moment'
import {
  formatMinutes,
  isMultipleFdtlSearchFormValid,
  transformFdtl,
} from 'src/utils/fdtl'
import classes from './styles.module.css'
import { notification } from 'antd'
import { useAuth } from 'src/auth/AuthProvider'
import { getPilotsFdtlListApi } from 'src/api/pilot'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { downloadCSV } from 'src/utils/export'

const getCellColor = (value: number, limit: number) => {
  if (limit === -1) {
    return 'black'
  }

  return value > limit ? 'red' : 'black'
}

const getLimit = (
  type: 'FLIGHT_DUTY' | 'FLYING_TIME',
  days: 0 | 7 | 28 | 365
) => {
  switch (days) {
    case 0:
      return type === 'FLYING_TIME' ? 360 : 600
    case 7:
      return type === 'FLYING_TIME' ? 1800 : 3600
    case 28:
      return type === 'FLYING_TIME' ? 6000 : 12000
    case 365:
      return type === 'FLYING_TIME' ? 60000 : -1
  }
}

const getDutyStatus = (fdtl: PilotFdtlType) => {
  if (fdtl.duty.length) {
    return (
      <Typography sx={{ color: 'green' }} textAlign='center' fontWeight='bold'>
        On Duty
      </Typography>
    )
  }

  return (
    <Typography sx={{ color: 'red' }} textAlign='center' fontWeight='bold'>
      Off Duty
    </Typography>
  )
}

function FdtlPage() {
  const { user } = useAuth()

  const [pilots, setPilots] = React.useState<PilotType[]>([])
  const [isPilotLoading, setIsPilotLoading] = React.useState(true)
  const [pilotsWithFdtl, setPilotsWithFdtl] =
    React.useState<PilotWithFdtlType[]>()
  const [isFdtlLoading, setIsFdtlLoading] = React.useState(false)
  const [pilotIds, setPilotIds] = React.useState([] as string[])
  const [startDate, setStartDate] = React.useState<Moment | null>(null)
  const [endDate, setEndDate] = React.useState<Moment | null>(null)
  const [formError, setFormError] = React.useState('')

  const getPilots = async () => {
    setIsPilotLoading(true)

    try {
      const pilots = await getPilotsApi()
      setPilots(pilots.data.pilots as PilotType[])
    } catch (err) {
      console.log(err)
    } finally {
      setIsPilotLoading(false)
    }
  }

  const getPilotFdtl = async (showError = true) => {
    const { isValid, error } = isMultipleFdtlSearchFormValid({
      startDate,
      endDate,
      pilotIds,
    })

    if (!isValid || !startDate || !endDate) {
      if (showError) {
        notification.error({
          message: 'Invalid input',
          description: error,
        })
        setFormError(error)
      }
      return
    }

    setIsFdtlLoading(true)
    setFormError('')

    try {
      const fdtls = await getPilotsFdtlListApi({
        pilotIds,
        startDateInMs: startDate.valueOf(),
        endDateInMs: endDate.valueOf(),
      })

      setPilotsWithFdtl(fdtls as PilotWithFdtlType[])
    } catch (err) {
      console.log(err)
    } finally {
      setIsFdtlLoading(false)
    }
  }

  const isLoading = React.useMemo(
    () => isPilotLoading || isFdtlLoading,
    [isPilotLoading, isFdtlLoading]
  )

  const onSelectAll = () => {
    setPilotIds(pilots.map(({ id }) => id))
  }

  React.useEffect(() => {
    getPilots()
  }, [])

  const onExport = () => {
    if (!pilotsWithFdtl) return
    const { names, fdtls } = transformFdtl(pilotsWithFdtl)

    const header = ['Date', '', '', ...names]
    const rows: string[][] = []

    fdtls.forEach(({ pilotFdtls, timestamp }) => {
      rows.push([
        formatTimestamp(timestamp),
        'ON/OFF Duty',
        '',
        ...pilotFdtls.map(({ flightTimeInMins }) =>
          flightTimeInMins.onDay > 0 ? 'On Duty' : 'Off Duty'
        ),
      ])

      rows.push([
        formatTimestamp(timestamp),
        'Flight Time',
        'On Date',
        ...pilotFdtls.map(({ flightTimeInMins }) =>
          formatMinutes(flightTimeInMins.onDay)
        ),
      ])

      rows.push([
        formatTimestamp(timestamp),
        'Flight Time',
        '7 Day',
        ...pilotFdtls.map(({ flightTimeInMins }) =>
          formatMinutes(flightTimeInMins.days7)
        ),
      ])

      rows.push([
        formatTimestamp(timestamp),
        'Flight Time',
        '28 Day',
        ...pilotFdtls.map(({ flightTimeInMins }) =>
          formatMinutes(flightTimeInMins.days28)
        ),
      ])

      rows.push([
        formatTimestamp(timestamp),
        'Flight Time',
        '365 Day',
        ...pilotFdtls.map(({ flightTimeInMins }) =>
          formatMinutes(flightTimeInMins.days365)
        ),
      ])

      rows.push([
        formatTimestamp(timestamp),
        'Flight Duty Time',
        'On Date',
        ...pilotFdtls.map(({ flightDutyInMins }) =>
          formatMinutes(flightDutyInMins.onDay)
        ),
      ])

      rows.push([
        formatTimestamp(timestamp),
        'Flight Duty Time',
        '7 Day',
        ...pilotFdtls.map(({ flightDutyInMins }) =>
          formatMinutes(flightDutyInMins.days7)
        ),
      ])

      rows.push([
        formatTimestamp(timestamp),
        'Flight Duty Time',
        '28 Day',
        ...pilotFdtls.map(({ flightDutyInMins }) =>
          formatMinutes(flightDutyInMins.days28)
        ),
      ])

      rows.push([
        formatTimestamp(timestamp),
        'Flight Duty Time',
        '365 Day',
        ...pilotFdtls.map(({ flightDutyInMins }) =>
          formatMinutes(flightDutyInMins.days365)
        ),
      ])
    })

    const csvContent =
      header.join(',') + '\n' + rows.map(row => row.join(',')).join('\n')

    downloadCSV(csvContent, 'fdtl_report.csv')
  }

  const isAdmin = !user?.roles.includes('FDTL_VIEWER')

  const transformedFdtl = transformFdtl(pilotsWithFdtl)

  return (
    <Box>
      {isLoading && <LoadingOverlay />}
      <Flex
        style={{
          marginTop: 5,
          width: '100%',
          background: 'white',
          padding: '20px 20px 15px 20px',
          flexWrap: 'wrap',
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        }}
        className={classes.searchBar}
      >
        <DatePicker
          label='Start Date'
          inputFormat='DD/MM/YYYY'
          views={['month', 'day']}
          value={startDate}
          onChange={setStartDate}
          renderInput={params => (
            <TextField
              {...params}
              variant='standard'
              sx={{
                caretColor: 'transparent',
                cursor: 'pointer',
                marginRight: 2,
              }}
            />
          )}
        />
        <DatePicker
          inputFormat='DD/MM/YYYY'
          label='End Date'
          views={['month', 'day']}
          value={endDate}
          onChange={setEndDate}
          disabled={!startDate}
          minDate={startDate || undefined}
          renderInput={params => (
            <TextField
              {...params}
              variant='standard'
              sx={{
                caretColor: 'transparent',
                cursor: 'pointer',
                marginRight: 2,
              }}
            />
          )}
        />

        <Box style={{ width: 300 }}>
          <FormControl fullWidth variant='standard'>
            <InputLabel id='demo-simple-select-label'>Pilot</InputLabel>
            <Select
              multiple
              value={pilotIds}
              label='Pilot'
              onChange={({ target: { value } }) => {
                console.log(value)
                if (value.includes('-1') && pilotIds.length !== pilots.length) {
                  setPilotIds(pilots.map(({ id }) => id))
                  return
                }

                if (value.includes('-1')) {
                  setPilotIds([])
                  return
                }
                setPilotIds(value as string[])
              }}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              renderValue={selected => {
                if (selected.length > 1) {
                  return `${selected.length} pilots selected`
                }
                return pilots
                  .filter(({ id }) => selected.includes(id))
                  .map(({ name }) => name)
                  .join(', ')
              }}
            >
              <MenuItem onClick={onSelectAll} value={'-1'}>
                <Checkbox
                  sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    minHeight: 'unset',
                  }}
                  checked={pilotIds.length === pilots.length}
                />
                <ListItemText primary={'Select All'} />
              </MenuItem>
              {pilots.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    sx={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      minHeight: 'unset',
                    }}
                    checked={pilotIds.indexOf(id) > -1}
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Flex style={{ marginLeft: 'auto' }}>
          {isAdmin && pilotsWithFdtl && (
            <Box style={{ alignSelf: 'center' }}>
              <Button
                variant='outlined'
                startIcon={<Download />}
                onClick={onExport}
              >
                Export
              </Button>
            </Box>
          )}
          <Box style={{ marginLeft: '5px', alignSelf: 'center' }}>
            <Button
              variant='contained'
              startIcon={<Search />}
              onClick={() => getPilotFdtl()}
            >
              Get FDTL report
            </Button>
          </Box>
        </Flex>
        <Box style={{ width: '100%', color: 'red', marginTop: 10 }}>
          <Typography>{formError}</Typography>
        </Box>
      </Flex>
      <Box className={classes.summaryHeader}>
        <Flex
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography>
              From :{' '}
              <b>
                {pilotsWithFdtl?.[0]?.fdtls[0] &&
                  formatTimestamp(pilotsWithFdtl?.[0]?.fdtls[0].date)}
              </b>{' '}
            </Typography>
            <Typography>
              To :{' '}
              <b>
                {pilotsWithFdtl?.[0]?.fdtls &&
                  formatTimestamp(
                    pilotsWithFdtl?.[0]?.fdtls[
                      pilotsWithFdtl?.[0]?.fdtls.length - 1
                    ]?.date
                  )}
              </b>
            </Typography>
            <Typography>
              Crew <b>{pilotsWithFdtl?.[0]?.pilot.name}</b>
            </Typography>
          </Box>
          <Box>
            <img
              style={{ height: 50, minWidth: 150 }}
              src='https://firebasestorage.googleapis.com/v0/b/helitaxii.appspot.com/o/35561521738665979.png?alt=media&amp;token=a873912b-9191-4d25-99fd-4094935ddcba'
              alt='HeliTaxii'
            />
          </Box>
        </Flex>
      </Box>
      <Box style={{ width: '100%' }}>
        {pilotsWithFdtl?.[0] && (
          <TableContainer
            component={Paper}
            className={classes.tableContainerViewer}
          >
            <Table size='small'>
              <TableHead
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  background: 'white',
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      border: 'none',
                      padding: 0,
                      minWidth: 154,
                    }}
                  >
                    <div
                      style={{
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        padding: '6px 16px',
                      }}
                    >
                      DATE
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      padding: 0,
                      minWidth: 154,
                    }}
                  >
                    <div
                      style={{
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        padding: '6px 16px',
                        color: 'white',
                      }}
                    >
                      -
                    </div>
                  </TableCell>{' '}
                  <TableCell
                    sx={{
                      border: 'none',
                      padding: 0,
                      minWidth: 154,
                    }}
                  >
                    <div
                      style={{
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        padding: '6px 16px',
                        color: 'white',
                      }}
                    >
                      -
                    </div>
                  </TableCell>
                  {transformedFdtl.names.map(name => (
                    <TableCell
                      key={name}
                      sx={{
                        border: 'none',
                        padding: 0,
                        minWidth: 300,
                      }}
                    >
                      <div
                        style={{
                          borderBottom: '1px solid rgba(224, 224, 224, 1)',
                          borderRight: '1px solid rgba(224, 224, 224, 1)',
                          padding: '6px 16px',
                        }}
                      >
                        {name}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {transformedFdtl.fdtls.map(
                  ({ timestamp, date, pilotFdtls }) => (
                    <React.Fragment key={date}>
                      <TableRow>
                        <TableCell
                          component='th'
                          scope='row'
                          rowSpan={9}
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          {formatTimestamp(timestamp)}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          ON/OFF Duty
                        </TableCell>
                        {pilotFdtls.map(({ flightTimeInMins }, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                            }}
                          >
                            {flightTimeInMins.onDay > 0 ? (
                              <Typography
                                sx={{ color: 'green' }}
                                textAlign='left'
                                fontWeight='bold'
                              >
                                On Duty
                              </Typography>
                            ) : (
                              <Typography
                                sx={{ color: 'red' }}
                                textAlign='left'
                                fontWeight='bold'
                              >
                                Off Duty
                              </Typography>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          rowSpan={4}
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          Flight Time
                        </TableCell>
                        <TableCell
                          align='right'
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          On Date
                        </TableCell>
                        {pilotFdtls.map(({ flightTimeInMins }, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                              color: getCellColor(
                                flightTimeInMins.onDay,
                                getLimit('FLYING_TIME', 0)
                              ),
                            }}
                          >
                            {formatMinutes(flightTimeInMins.onDay)}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align='right'
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          7 Day
                        </TableCell>
                        {pilotFdtls.map(({ flightTimeInMins }, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                              color: getCellColor(
                                flightTimeInMins.days7,
                                getLimit('FLYING_TIME', 7)
                              ),
                            }}
                          >
                            {formatMinutes(flightTimeInMins.days7)}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align='right'
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          28 Day
                        </TableCell>
                        {pilotFdtls.map(({ flightTimeInMins }, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                              color: getCellColor(
                                flightTimeInMins.days28,
                                getLimit('FLYING_TIME', 28)
                              ),
                            }}
                          >
                            {formatMinutes(flightTimeInMins.days28)}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align='right'
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          365 Day
                        </TableCell>
                        {pilotFdtls.map(({ flightTimeInMins }, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                              color: getCellColor(
                                flightTimeInMins.days365,
                                getLimit('FLYING_TIME', 365)
                              ),
                            }}
                          >
                            {formatMinutes(flightTimeInMins.days365)}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          rowSpan={4}
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          Flight Duty Time
                        </TableCell>
                        <TableCell
                          align='right'
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          On Date
                        </TableCell>
                        {pilotFdtls.map(({ flightDutyInMins }, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                              color: getCellColor(
                                flightDutyInMins.onDay,
                                getLimit('FLIGHT_DUTY', 0)
                              ),
                            }}
                          >
                            {formatMinutes(flightDutyInMins.onDay)}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align='right'
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          7 Day
                        </TableCell>
                        {pilotFdtls.map(({ flightDutyInMins }, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                              color: getCellColor(
                                flightDutyInMins.days7,
                                getLimit('FLIGHT_DUTY', 7)
                              ),
                            }}
                          >
                            {formatMinutes(flightDutyInMins.days7)}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align='right'
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          28 Day
                        </TableCell>
                        {pilotFdtls.map(({ flightDutyInMins }, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                              color: getCellColor(
                                flightDutyInMins.days28,
                                getLimit('FLIGHT_DUTY', 28)
                              ),
                            }}
                          >
                            {formatMinutes(flightDutyInMins.days28)}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align='right'
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          365 Day
                        </TableCell>
                        {pilotFdtls.map(({ flightDutyInMins }, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                              color: getCellColor(
                                flightDutyInMins.days365,
                                getLimit('FLIGHT_DUTY', 365)
                              ),
                            }}
                          >
                            {formatMinutes(flightDutyInMins.days365)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </React.Fragment>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  )
}

export default withPageBody(FdtlPage, true)
