import { CircularProgress } from '@mui/material'
import React from 'react'
import { Flex } from 'src/styles/Layout'

const LoadingOverlay = () => {
  return (
    <Flex
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 2000,
      }}
    >
      <CircularProgress />
    </Flex>
  )
}

export default LoadingOverlay
