import React from 'react'
import Router from 'src/router'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateAdapter from '@mui/lab/AdapterMoment'

import NavBar from 'src/components/NavBar'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { AuthProvider, AuthContext } from 'src/auth/AuthProvider'

function App() {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <AuthProvider>
        <AuthContext.Consumer>
          {value => (
            <>
              {value?.isLoading && <LoadingOverlay />}
              <NavBar />
              <Router />
            </>
          )}
        </AuthContext.Consumer>
      </AuthProvider>
    </LocalizationProvider>
  )
}

export default App
