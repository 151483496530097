import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { useTheme } from '@mui/material/styles'
import { Link, NavLink } from 'react-router-dom'
import LogoImage from 'src/images/logo-thumby.png'

import FirebaseAuth from 'src/auth/FirebaseAuth'
import { useAuth } from 'src/auth/AuthProvider'

import classes from './styles.module.css'

export default function MenuAppBar() {
  const { user } = useAuth() || {}
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleMenu: React.MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    FirebaseAuth.signOut()
    setAnchorEl(null)
  }

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.navBar}>
      <AppBar position='static' style={{ background: 'white' }}>
        <Toolbar>
          <Link to='/'>
            <img style={{ height: 50 }} src={LogoImage} alt='HeliTaxii' />
          </Link>
          <Typography
            variant='h6'
            color='primary'
            fontWeight='bold'
            sx={{ ml: 1 }}
          >
            FMS
          </Typography>

          {user && (
            <>
              {!user.roles.includes('FDTL_VIEWER') && (
                <>
                  <div style={{ marginLeft: 80 }}>
                    <NavLink
                      to='/pilots'
                      style={({ isActive }) => ({
                        color: isActive
                          ? theme.palette.secondary.main
                          : 'black',
                      })}
                    >
                      <Typography
                        variant='h6'
                        component='div'
                        style={{
                          textDecoration: 'none',
                        }}
                      >
                        Pilots
                      </Typography>
                    </NavLink>
                  </div>

                  <div style={{ marginLeft: 40 }}>
                    <NavLink
                      to='/machines'
                      style={({ isActive }) => ({
                        color: isActive
                          ? theme.palette.secondary.main
                          : 'black',
                      })}
                    >
                      <Typography
                        variant='h6'
                        component='div'
                        style={{
                          textDecoration: 'none',
                        }}
                      >
                        Aircrafts
                      </Typography>
                    </NavLink>
                  </div>
                </>
              )}
              <div style={{ marginLeft: 40 }}>
                <NavLink
                  to='/fdtls'
                  style={({ isActive }) => ({
                    color: isActive ? theme.palette.secondary.main : 'black',
                  })}
                >
                  <Typography
                    variant='h6'
                    component='div'
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    Flight Duty
                  </Typography>
                </NavLink>
              </div>
              <div style={{ marginLeft: 40 }}>
                <NavLink
                  to='/collective-fdtls'
                  style={({ isActive }) => ({
                    color: isActive ? theme.palette.secondary.main : 'black',
                  })}
                >
                  <Typography
                    variant='h6'
                    component='div'
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    Collective Flight Duty
                  </Typography>
                </NavLink>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton
                  size='large'
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleMenu}
                  color='inherit'
                >
                  <AccountCircle sx={{ color: 'black' }} />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}
