import moment from 'moment'
import { Timestamp } from 'src/types/common'

export const formatTimestamp = (
  timestamp: Timestamp | null | undefined,
  format = 'DD MMM YYYY'
) => {
  if (timestamp === undefined) {
    return moment('May 7, 2022 11:11:29 PM').utcOffset('+05:30').format(format)
  }

  if (timestamp === null) {
    return '-'
  }

  return moment(timestamp._seconds * 1000)
    .utcOffset('+05:30')
    .format(format)
}

export const getHours = () => {
  const hours = []

  for (let i = 0; i <= 24; i++) {
    if (i < 10) {
      hours.push(`0${i}`)
    } else {
      hours.push(`${i}`)
    }
  }
  return hours
}

export const getMinutes = () => {
  const minutes = []

  for (let i = 0; i < 60; i = i + 5) {
    if (i < 10) {
      minutes.push(`0${i}`)
    } else {
      minutes.push(`${i}`)
    }
  }

  return minutes
}

export const prependZero = (timePart: string) =>
  timePart.length > 1 || timePart === '' ? timePart : `0${timePart}`
