import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#125299',
    },
    secondary: {
      main: '#e1b149',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Nunito Sans, sans-serif',
      fontWeight: 400,
    },
  },
})

export default theme
