import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from 'src/auth/AuthProvider'
import { Box, Flex } from 'src/styles/Layout'
import firebaseAuth from 'src/auth/FirebaseAuth'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import withPageBody from 'src/hoc/PageBody'
import { Alert, AlertTitle, Typography } from '@mui/material'

const LoginPage = () => {
  const { user, setIsLoading } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const onLogin = useCallback(async () => {
    setError('')
    setIsLoading(true)
    try {
      await firebaseAuth.signInWithEmail(email, password)
    } catch (err) {
      setError('Login failed. Invalid email/password.')
    } finally {
      setIsLoading(false)
    }
  }, [email, password])

  const onChangeEmail: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setEmail(value)
  }

  const onChangePassword: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setPassword(value)
  }

  useEffect(() => {
    if (user) {
      const toLocation = (location.state as any)?.from?.pathname || '/fdtls'
      navigate(toLocation)
    }
  }, [user])

  return (
    <Flex
      style={{ alignItems: 'center', justifyContent: 'center', marginTop: 100 }}
    >
      <Box style={{ minWidth: 500 }}>
        <Card>
          <CardContent>
            <Box>
              <Flex style={{ padding: 20 }}>
                <TextField
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  value={email}
                  onChange={onChangeEmail}
                  fullWidth
                />
              </Flex>
              <Flex style={{ padding: 20 }}>
                <TextField
                  id='outlined-basic'
                  label='Password'
                  variant='outlined'
                  type='password'
                  value={password}
                  onChange={onChangePassword}
                  fullWidth
                />
              </Flex>
              {error && (
                <Box style={{ padding: 20 }}>
                  <Alert severity='error' variant='outlined'>
                    <AlertTitle>Error</AlertTitle>
                    <Typography>{error}</Typography>
                  </Alert>
                </Box>
              )}
              <Flex style={{ padding: 20, justifyContent: 'center' }}>
                <Button variant='contained' onClick={onLogin}>
                  Login
                </Button>
              </Flex>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Flex>
  )
}

export default withPageBody(LoginPage)
