import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import {
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
} from '@mui/material'

import { createMachineApi } from 'src/api'
import { MachineType, MachineTypeEnum } from 'src/types/machine'
import { editMachineApi } from 'src/api/machine'

interface CreateMachineModalProps {
  onClose: () => void
  beforeCreate: () => void
  afterCreate: () => void
  machineForEdit: MachineType | null
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const CreateMachineModal = ({
  onClose,
  beforeCreate,
  afterCreate,
  machineForEdit,
}: CreateMachineModalProps) => {
  const [callSign, setCallSign] = useState(machineForEdit?.callSign || '')
  const [modelNumber, setModelNumber] = useState(machineForEdit?.modelNo || '')
  const [type, setType] = useState<string>(machineForEdit?.type || '')
  const isEdit = !!machineForEdit

  const onChangeCallSign: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setCallSign(value)
  }

  const onChangeModelNumber: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setModelNumber(value)
  }

  const onAddMachine: React.MouseEventHandler<HTMLButtonElement> = async () => {
    const trimmedCallSign = callSign.trim()
    const trimmedModelNumber = modelNumber.trim()

    if (trimmedCallSign && trimmedModelNumber && type) {
      beforeCreate()
      try {
        if (isEdit) {
          await editMachineApi(
            {
              callSign: trimmedCallSign,
              modelNo: trimmedModelNumber,
              type: type as MachineTypeEnum,
            },
            machineForEdit.id
          )
        } else {
          await createMachineApi({
            callSign: trimmedCallSign,
            modelNo: trimmedModelNumber,
            type: type as MachineTypeEnum,
          })
        }
      } catch (err) {
        console.log(err)
      } finally {
        afterCreate()
      }
    }
  }

  const onChangeType: (event: SelectChangeEvent<string | null>) => void = (
    { target: { value } },
    ...args
  ) => {
    console.log(args)
    setType(value as MachineTypeEnum)
  }

  return (
    <Modal
      open
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography
          id='modal-modal-title'
          variant='h6'
          component='h2'
          fontWeight='bold'
        >
          {isEdit ? 'Edit' : 'Add'} Aircraft
        </Typography>
        <Box mt={2}>
          <TextField
            fullWidth
            id='outlined-basic'
            label='Call Sign'
            variant='outlined'
            value={callSign}
            onChange={onChangeCallSign}
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            id='outlined-basic'
            label='Model Number'
            variant='outlined'
            value={modelNumber}
            onChange={onChangeModelNumber}
          />
        </Box>
        <Box mt={2}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Aircraft Type</InputLabel>
            <Select
              value={type}
              label='Aircraft Type'
              onChange={onChangeType}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
            >
              <MenuItem value={MachineTypeEnum.HELICOPTER}>Helicopter</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box mt={2} textAlign='right'>
          <Button variant='contained' onClick={onAddMachine}>
            {isEdit ? 'Edit' : 'Add'}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default CreateMachineModal
