/* eslint-disable indent */
import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, Flex } from 'src/styles/Layout'
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  IconButton,
} from '@mui/material'
import { Add, Download, Search } from '@mui/icons-material'
import withPageBody from 'src/hoc/PageBody'
import { getPilotsApi, getMachinesApi, getPilotFdtlListApi } from 'src/api'
import { PilotFdtlType, PilotType, PilotWithFdtlType } from 'src/types/pilot'
import { formatTimestamp } from 'src/utils/moment'
import LoadingOverlay from 'src/components/LoadingOverlay'
import { MachineType } from 'src/types/machine'
import DatePicker from '@mui/lab/DatePicker'
import { Moment } from 'moment'
import { formatMinutes, isFdtlSearchFormValid } from 'src/utils/fdtl'
import FdtlModal from './FdtlModal'
import classes from './styles.module.css'
import { notification } from 'antd'
import { useAuth } from 'src/auth/AuthProvider'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmModal from 'src/components/ConfirmModal'
import { deleteFdtlApi } from 'src/api/pilot'
import { tableHeaders } from './tableData'
import { downloadCSV } from 'src/utils/export'

const getCellColor = (value: number, limit: number) => {
  if (limit === -1) {
    return 'black'
  }

  return value > limit ? 'red' : 'black'
}

const getLimit = (
  type: 'FLIGHT_DUTY' | 'FLYING_TIME',
  days: 0 | 7 | 28 | 365
) => {
  switch (days) {
    case 0:
      return type === 'FLYING_TIME' ? 360 : 600
    case 7:
      return type === 'FLYING_TIME' ? 1800 : 3600
    case 28:
      return type === 'FLYING_TIME' ? 6000 : 12000
    case 365:
      return type === 'FLYING_TIME' ? 60000 : -1
  }
}

const getDutyStatusString = (fdtl: PilotFdtlType) => {
  if (fdtl.duty.length) {
    return 'On Duty'
  }

  return 'Off Duty'
}

const getDutyStatus = (fdtl: PilotFdtlType) => {
  return (
    <Typography
      sx={{ color: fdtl.duty.length ? 'green' : 'red' }}
      textAlign='center'
      fontWeight='bold'
    >
      {getDutyStatusString(fdtl)}
    </Typography>
  )
}

function FdtlPage() {
  const { user } = useAuth()

  const [pilots, setPilots] = React.useState<PilotType[]>([])
  const [isPilotLoading, setIsPilotLoading] = React.useState(true)
  const [pilotWithFdtl, setPilotWithFdtl] = React.useState<PilotWithFdtlType>()
  const [machines, setMachines] = React.useState<MachineType[]>([])
  const [isMachineLoading, setIsMachineLoading] = React.useState(true)
  const [isFdtlLoading, setIsFdtlLoading] = React.useState(false)
  const [pilotId, setPilotId] = React.useState('')
  const [startDate, setStartDate] = React.useState<Moment | null>(null)
  const [endDate, setEndDate] = React.useState<Moment | null>(null)
  const [formError, setFormError] = React.useState('')
  const [isFdtlModalOpen, setIsFdtlModalOpen] = React.useState(false)
  const [selectedFdtlForDelete, setSelectedFdtlForDelete] =
    React.useState<PilotFdtlType | null>(null)

  const getMachines = async () => {
    setIsMachineLoading(true)

    try {
      const machines = await getMachinesApi()
      setMachines(machines.data.machines as MachineType[])
    } catch (err) {
      console.log(err)
    } finally {
      setIsMachineLoading(false)
    }
  }

  const getPilots = async () => {
    setIsPilotLoading(true)

    try {
      const pilots = await getPilotsApi()
      setPilots(pilots.data.pilots as PilotType[])
    } catch (err) {
      console.log(err)
    } finally {
      setIsPilotLoading(false)
    }
  }

  const openFdtlModal = () => {
    setIsFdtlModalOpen(true)
  }

  const closeFdtlModal = () => {
    setIsFdtlModalOpen(false)
  }

  const getPilotFdtl = async (showError = true) => {
    const { isValid, error } = isFdtlSearchFormValid({
      startDate,
      endDate,
      pilotId,
    })

    if (!isValid || !startDate || !endDate) {
      if (showError) {
        notification.error({
          message: 'Invalid input',
          description: error,
        })
        setFormError(error)
      }
      return
    }

    setIsFdtlLoading(true)
    setFormError('')

    try {
      const fdtls = await getPilotFdtlListApi({
        pilotId,
        startDateInMs: startDate.valueOf(),
        endDateInMs: endDate.valueOf(),
      })

      setPilotWithFdtl(fdtls.data as PilotWithFdtlType)
    } catch (err) {
      console.log(err)
    } finally {
      setIsFdtlLoading(false)
    }
  }

  const isLoading = React.useMemo(
    () => isPilotLoading || isMachineLoading || isFdtlLoading,
    [isPilotLoading, isMachineLoading, isFdtlLoading]
  )

  React.useEffect(() => {
    getPilots()
    getMachines()
  }, [])

  const getMachineCallSign = (machineId?: string) =>
    machines.find(({ id }) => id === machineId)?.callSign || '-'

  const afterCreate = () => {
    setIsFdtlLoading(false)
    getPilotFdtl(false)
  }

  const beforeCreate = () => {
    setIsFdtlLoading(true)
  }

  const onDeleteFdtl = (fdtl: PilotFdtlType) => () => {
    setSelectedFdtlForDelete(fdtl)
  }

  const closeFdtlConfirmationModal = () => {
    setSelectedFdtlForDelete(null)
  }

  const deleteFdtl = async () => {
    if (!selectedFdtlForDelete?.id) {
      return
    }

    setIsFdtlLoading(true)

    try {
      await deleteFdtlApi({
        fdtlId: selectedFdtlForDelete.id,
        pilotId,
      })

      afterCreate()
    } catch (err) {
      console.log(err)
    } finally {
      closeFdtlConfirmationModal()
    }
  }

  const onExportData = () => {
    const headerString = tableHeaders
      .map(header =>
        header
          .map(cell => {
            const title = cell.title === '-' ? '' : cell.title
            // Add empty spaces based on colSpan
            if (cell.colSpan && cell.colSpan > 1) {
              return [title, ...Array(cell.colSpan - 1).fill('')].join(',')
            } else {
              return title
            }
          })
          .join(',')
      )
      .join('\n')

    const bodyString = pilotWithFdtl?.fdtls
      .map(fdtl => {
        return [
          formatTimestamp(fdtl.date),
          getDutyStatusString(fdtl),
          getMachineCallSign(fdtl.duty[0]?.machineId),
          formatMinutes(fdtl.duty[0]?.startMinutesFromMidnight),
          formatMinutes(fdtl.duty[0]?.endMinutesFromMidnight),
          formatMinutes(fdtl.duty[0]?.flightDurationInMinutes),
          getMachineCallSign(fdtl.duty[1]?.machineId),
          formatMinutes(fdtl.duty[1]?.startMinutesFromMidnight),
          formatMinutes(fdtl.duty[1]?.endMinutesFromMidnight),
          formatMinutes(fdtl.duty[1]?.flightDurationInMinutes),
          getMachineCallSign(fdtl.duty[2]?.machineId),
          formatMinutes(fdtl.duty[2]?.startMinutesFromMidnight),
          formatMinutes(fdtl.duty[2]?.endMinutesFromMidnight),
          formatMinutes(fdtl.duty[2]?.flightDurationInMinutes),
          formatMinutes(fdtl.aggregate.flightTimeInMins.onDay),
          formatMinutes(fdtl.aggregate.flightTimeInMins.days7),
          formatMinutes(fdtl.aggregate.flightTimeInMins.days28),
          formatMinutes(fdtl.aggregate.flightTimeInMins.days365),
          formatMinutes(fdtl.aggregate.flightDutyInMins.onDay),
          formatMinutes(fdtl.aggregate.flightDutyInMins.days7),
          formatMinutes(fdtl.aggregate.flightDutyInMins.days28),
          formatMinutes(fdtl.aggregate.flightDutyInMins.days365),
          formatTimestamp(fdtl.createdAt, 'DD MMM YYYY hh:mm a'),
          formatTimestamp(fdtl.updatedAt, 'DD MMM YYYY hh:mm a'),
        ].join(',')
      })
      .join('\n')

    downloadCSV(`${headerString}\n${bodyString}`, 'fdtl.csv')
  }

  const isAdmin = !user?.roles.includes('FDTL_VIEWER')

  return (
    <>
      {isLoading && <LoadingOverlay />}
      {isFdtlModalOpen && (
        <FdtlModal
          onClose={closeFdtlModal}
          pilots={pilots}
          machines={machines}
          beforeCreate={beforeCreate}
          afterCreate={afterCreate}
        />
      )}
      {isAdmin && (
        <Box
          style={{ alignSelf: 'flex-end', marginTop: 10, marginBottom: 10 }}
          className={classes.addButton}
        >
          <Button
            variant='outlined'
            startIcon={<Add />}
            onClick={openFdtlModal}
          >
            Add FDTL
          </Button>
        </Box>
      )}
      <Flex
        style={{
          marginTop: 5,
          width: '100%',
          background: 'white',
          padding: '20px 20px 15px 20px',
          flexWrap: 'wrap',
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        }}
        className={classes.searchBar}
      >
        <DatePicker
          label='Start Date'
          inputFormat='DD/MM/YYYY'
          views={['month', 'day']}
          value={startDate}
          onChange={setStartDate}
          renderInput={params => (
            <TextField
              {...params}
              variant='standard'
              sx={{
                caretColor: 'transparent',
                cursor: 'pointer',
                marginRight: 2,
              }}
            />
          )}
        />
        <DatePicker
          inputFormat='DD/MM/YYYY'
          label='End Date'
          views={['month', 'day']}
          value={endDate}
          onChange={setEndDate}
          disabled={!startDate}
          minDate={startDate || undefined}
          renderInput={params => (
            <TextField
              {...params}
              variant='standard'
              sx={{
                caretColor: 'transparent',
                cursor: 'pointer',
                marginRight: 2,
              }}
            />
          )}
        />

        <Box style={{ width: 200 }}>
          <FormControl fullWidth variant='standard'>
            <InputLabel id='demo-simple-select-label'>Pilot</InputLabel>
            <Select
              value={pilotId}
              label='Pilot'
              onChange={({ target: { value } }) => setPilotId(value)}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
            >
              {pilots.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Flex style={{ marginLeft: 'auto' }}>
          {isAdmin && pilotWithFdtl && (
            <Box style={{ marginRight: '5px', alignSelf: 'center' }}>
              <Button
                variant='outlined'
                startIcon={<Download />}
                onClick={onExportData}
              >
                Export
              </Button>
            </Box>
          )}
          <Box style={{ alignSelf: 'center' }}>
            <Button
              variant='contained'
              startIcon={<Search />}
              onClick={() => getPilotFdtl()}
            >
              Get FDTL report
            </Button>
          </Box>
        </Flex>
        <Box style={{ width: '100%', color: 'red', marginTop: 10 }}>
          <Typography>{formError}</Typography>
        </Box>
      </Flex>
      <Box className={classes.summaryHeader}>
        <Flex
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography>
              From :{' '}
              <b>
                {pilotWithFdtl?.fdtls[0] &&
                  formatTimestamp(pilotWithFdtl?.fdtls[0].date)}
              </b>{' '}
            </Typography>
            <Typography>
              To :{' '}
              <b>
                {pilotWithFdtl?.fdtls &&
                  formatTimestamp(
                    pilotWithFdtl?.fdtls[pilotWithFdtl?.fdtls.length - 1]?.date
                  )}
              </b>
            </Typography>
            <Typography>
              Crew <b>{pilotWithFdtl?.pilot.name}</b>
            </Typography>
          </Box>
          <Box>
            <img
              style={{ height: 50, minWidth: 150 }}
              src='https://firebasestorage.googleapis.com/v0/b/helitaxii.appspot.com/o/35561521738665979.png?alt=media&amp;token=a873912b-9191-4d25-99fd-4094935ddcba'
              alt='HeliTaxii'
            />
          </Box>
        </Flex>
      </Box>
      <Box style={{ width: '100%' }}>
        {pilotWithFdtl && (
          <TableContainer
            component={Paper}
            className={
              isAdmin ? classes.tableContainer : classes.tableContainerViewer
            }
          >
            <Table size='small'>
              <TableHead
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  background: 'white',
                }}
              >
                {tableHeaders.map((tableHeader, index) => (
                  <TableRow key={index}>
                    {tableHeader.map((column, index) => (
                      <TableCell
                        key={index}
                        component='th'
                        align='center'
                        colSpan={column.colSpan}
                        sx={column.sx}
                      >
                        <div style={column.style}>{column.title}</div>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {pilotWithFdtl?.fdtls.map(fdtl => (
                  <TableRow key={`${fdtl.id}`}>
                    <TableCell
                      scope='row'
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <Flex
                        style={{
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {formatTimestamp(fdtl.date)}
                        {isAdmin && (
                          <IconButton
                            color='warning'
                            aria-label='Delete FDTL'
                            onClick={onDeleteFdtl(fdtl)}
                            sx={{
                              ml: '5px',
                            }}
                            title='Delete FDTL'
                            size='small'
                          >
                            <DeleteIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        )}
                      </Flex>
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {getDutyStatus(fdtl)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {getMachineCallSign(fdtl.duty[0]?.machineId)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {formatMinutes(fdtl.duty[0]?.startMinutesFromMidnight)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {formatMinutes(fdtl.duty[0]?.endMinutesFromMidnight)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {formatMinutes(fdtl.duty[0]?.flightDurationInMinutes)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {getMachineCallSign(fdtl.duty[1]?.machineId)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {formatMinutes(fdtl.duty[1]?.startMinutesFromMidnight)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {formatMinutes(fdtl.duty[1]?.endMinutesFromMidnight)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {formatMinutes(fdtl.duty[1]?.flightDurationInMinutes)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {getMachineCallSign(fdtl.duty[2]?.machineId)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {formatMinutes(fdtl.duty[2]?.startMinutesFromMidnight)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {formatMinutes(fdtl.duty[2]?.endMinutesFromMidnight)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                      {formatMinutes(fdtl.duty[2]?.flightDurationInMinutes)}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        color: getCellColor(
                          fdtl.aggregate.flightTimeInMins.onDay,
                          getLimit('FLYING_TIME', 0)
                        ),
                      }}
                    >
                      {formatMinutes(fdtl.aggregate.flightTimeInMins.onDay)}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        color: getCellColor(
                          fdtl.aggregate.flightTimeInMins.days7,
                          getLimit('FLYING_TIME', 7)
                        ),
                      }}
                    >
                      {formatMinutes(fdtl.aggregate.flightTimeInMins.days7)}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        color: getCellColor(
                          fdtl.aggregate.flightTimeInMins.days28,
                          getLimit('FLYING_TIME', 28)
                        ),
                      }}
                    >
                      {formatMinutes(fdtl.aggregate.flightTimeInMins.days28)}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        color: getCellColor(
                          fdtl.aggregate.flightTimeInMins.days365,
                          getLimit('FLYING_TIME', 365)
                        ),
                      }}
                    >
                      {formatMinutes(fdtl.aggregate.flightTimeInMins.days365)}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        color: getCellColor(
                          fdtl.aggregate.flightDutyInMins.onDay,
                          getLimit('FLIGHT_DUTY', 0)
                        ),
                      }}
                    >
                      {formatMinutes(fdtl.aggregate.flightDutyInMins.onDay)}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        color: getCellColor(
                          fdtl.aggregate.flightDutyInMins.days7,
                          getLimit('FLIGHT_DUTY', 7)
                        ),
                      }}
                    >
                      {formatMinutes(fdtl.aggregate.flightDutyInMins.days7)}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        color: getCellColor(
                          fdtl.aggregate.flightDutyInMins.days28,
                          getLimit('FLIGHT_DUTY', 28)
                        ),
                      }}
                    >
                      {formatMinutes(fdtl.aggregate.flightDutyInMins.days28)}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        color: getCellColor(
                          fdtl.aggregate.flightDutyInMins.days365,
                          getLimit('FLIGHT_DUTY', 365)
                        ),
                      }}
                    >
                      {formatMinutes(fdtl.aggregate.flightDutyInMins.days365)}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      {formatTimestamp(fdtl.createdAt, 'DD MMM YYYY hh:mm a')}
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      {formatTimestamp(fdtl.updatedAt, 'DD MMM YYYY hh:mm a')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <ConfirmModal
        open={!!selectedFdtlForDelete}
        onClose={closeFdtlConfirmationModal}
        onConfirm={deleteFdtl}
        title='Delete FDTL'
        description={`Are you sure you want to delete the FDTL for ${formatTimestamp(
          selectedFdtlForDelete?.date
        )} ?`}
      />
    </>
  )
}

export default withPageBody(FdtlPage, true)
