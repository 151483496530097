import axios from 'axios'
import getEnv from 'src/env'
import { getAuth } from 'firebase/auth'

const axiosInstance = axios.create({
  baseURL: getEnv().apiBaseUrl,
  headers: { 'x-organisation-id': getEnv().organisationId },
})

axiosInstance.interceptors.request.use(async config => {
  if (!config.headers) {
    config.headers = {}
  }

  const userToken = await getAuth().currentUser?.getIdToken()
  config.headers.Authorization = `Bearer ${userToken}`
  return config
})

axiosInstance.interceptors.response.use(response => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response.data
})
export default axiosInstance
