import moment, { Moment } from 'moment'
import { FdtlFormType } from 'src/types/fdtl'
import {
  DutyInputType,
  TimePeriodType,
  FlightDutyType,
  PilotWithFdtlType,
  FdtlAggregateType,
} from 'src/types/pilot'
import { prependZero } from './moment'
import { Timestamp } from 'src/types/common'

export const formatMinutes = (minutes?: number) => {
  if (typeof minutes !== 'number') {
    return '-'
  }
  const numberOfHours = Math.floor(minutes / 60)
  const numberOfRemainderMinutes = minutes % 60

  const hoursString = numberOfHours < 10 ? `0${numberOfHours}` : numberOfHours
  const minutesString =
    numberOfRemainderMinutes < 10
      ? `0${numberOfRemainderMinutes}`
      : numberOfRemainderMinutes

  return `${hoursString}:${minutesString}`
}

const isTimePeriodValid = (timePeriod: TimePeriodType) => {
  const hoursInt = parseInt(timePeriod.hours, 10)
  const minutesInt = parseInt(timePeriod.minutes, 10)

  return (
    !!timePeriod.hours &&
    !isNaN(hoursInt) &&
    hoursInt >= 0 &&
    hoursInt <= 23 &&
    !!timePeriod.minutes &&
    !isNaN(minutesInt) &&
    minutesInt >= 0 &&
    minutesInt <= 59
  )
}

export const isDutyTimeValid = (duty: DutyInputType) => {
  if (!isTimePeriodValid(duty.dutyStartTime)) {
    return {
      isValid: false,
      message: 'start time is invalid',
    }
  }

  if (!isTimePeriodValid(duty.dutyEndTime)) {
    return {
      isValid: false,
      message: 'end time is invalid',
    }
  }

  if (!isTimePeriodValid(duty.flyingTime)) {
    return {
      isValid: false,
      message: 'Flying time is invalid',
    }
  }

  if (!duty.machineId) {
    return {
      isValid: false,
      message: 'Machine id invalid',
    }
  }

  return {
    isValid: true,
    message: '',
  }
}

export const areFlightDutiesValid = ({
  firstDuty,
  secondDuty,
  thirdDuty,
}: FdtlFormType): { isValid: boolean; error?: string } => {
  const { isValid: isFirstDutyValid, message: firstDutyMessage } =
    isDutyTimeValid(firstDuty)

  if (!isFirstDutyValid) {
    return {
      isValid: isFirstDutyValid,
      error: isFirstDutyValid ? '' : `First duty ${firstDutyMessage}`,
    }
  }

  const firstDutyStartInMins = getMinutesFromMidnight(firstDuty.dutyStartTime)
  const firstDutyEndInMins = getMinutesFromMidnight(firstDuty.dutyEndTime)

  if (firstDutyStartInMins > firstDutyEndInMins) {
    return {
      isValid: false,
      error: 'First Duty start time should not be after end time',
    }
  }

  if (!secondDuty) {
    return {
      isValid: true,
    }
  }

  const { isValid: isSecondDutyValid, message: secondDutyMessage } =
    isDutyTimeValid(secondDuty)

  if (!isSecondDutyValid) {
    return {
      isValid: isSecondDutyValid,
      error: isSecondDutyValid
        ? ''
        : `Second duty ${secondDutyMessage}. Remove Second duty if not required.`,
    }
  }

  const secondDutyStartInMins = getMinutesFromMidnight(secondDuty.dutyStartTime)
  const secondDutyEndInMins = getMinutesFromMidnight(secondDuty.dutyEndTime)

  if (secondDutyStartInMins > secondDutyEndInMins) {
    return {
      isValid: false,
      error: 'Second Duty start time should not be after end time',
    }
  }

  if (firstDutyEndInMins > secondDutyStartInMins) {
    return {
      isValid: false,
      error: 'Second Duty cannot start before the First duty',
    }
  }

  if (!thirdDuty) {
    return {
      isValid: true,
    }
  }

  const { isValid: isThirdDutyValid, message: thirdDutyMessage } =
    isDutyTimeValid(thirdDuty)

  if (!isThirdDutyValid) {
    return {
      isValid: isThirdDutyValid,
      error: isThirdDutyValid
        ? ''
        : `Third duty ${thirdDutyMessage}. Remove Third duty if not required.`,
    }
  }

  const thirdDutyStartInMins = getMinutesFromMidnight(thirdDuty.dutyStartTime)
  const thirdDutyEndInMins = getMinutesFromMidnight(thirdDuty.dutyEndTime)

  if (thirdDutyStartInMins > thirdDutyEndInMins) {
    return {
      isValid: false,
      error: 'Third Duty start time should not be after end time',
    }
  }

  if (secondDutyEndInMins > thirdDutyStartInMins) {
    return {
      isValid: false,
      error: 'Third Duty cannot start before the Second duty',
    }
  }

  return {
    isValid: true,
  }
}

const getMinutesFromMidnight = (timePeriod: TimePeriodType) => {
  const { hours, minutes } = timePeriod

  if (hours === '' || minutes === '') {
    return 0
  }

  const numericHours = parseInt(hours, 10)
  const numericMinutes = parseInt(minutes, 10)

  return numericHours * 60 + numericMinutes
}

const getTimePeriod = (minutesfromMidnight: number): TimePeriodType => {
  const hoursInNumber = Math.floor(minutesfromMidnight / 60)
  const minutesInNumber = minutesfromMidnight % 60

  const hours = hoursInNumber.toString()
  const minutes = minutesInNumber.toString()

  return {
    hours: hours.length === 1 ? `0${hours}` : hours,
    minutes: minutes.length === 1 ? `0${minutes}` : minutes,
  }
}

const getDutyInitialState = (): DutyInputType => ({
  dutyStartTime: {
    hours: '',
    minutes: '',
  },
  dutyEndTime: {
    hours: '',
    minutes: '',
  },
  flyingTime: {
    hours: '',
    minutes: '',
  },
  machineId: '',
})

export const getFdtlDutyInputType = (duty: FlightDutyType[]): FdtlFormType => {
  const firstDuty = duty.find(({ sequence }) => sequence === 1)
  const secondDuty = duty.find(({ sequence }) => sequence === 2)
  const thirdDuty = duty.find(({ sequence }) => sequence === 3)

  const fdtlForm: FdtlFormType = {
    firstDuty: getDutyInitialState(),
    secondDuty: secondDuty ? getDutyInitialState() : null,
    thirdDuty: thirdDuty ? getDutyInitialState() : null,
  }

  if (firstDuty) {
    fdtlForm.firstDuty = {
      dutyStartTime: getTimePeriod(firstDuty.startMinutesFromMidnight),
      dutyEndTime: getTimePeriod(firstDuty.endMinutesFromMidnight),
      flyingTime: getTimePeriod(firstDuty.flightDurationInMinutes),
      machineId: firstDuty.machineId,
    }
  }

  if (secondDuty) {
    fdtlForm.secondDuty = {
      dutyStartTime: getTimePeriod(secondDuty.startMinutesFromMidnight),
      dutyEndTime: getTimePeriod(secondDuty.endMinutesFromMidnight),
      flyingTime: getTimePeriod(secondDuty.flightDurationInMinutes),
      machineId: secondDuty.machineId,
    }
  }

  if (thirdDuty) {
    fdtlForm.thirdDuty = {
      dutyStartTime: getTimePeriod(thirdDuty.startMinutesFromMidnight),
      dutyEndTime: getTimePeriod(thirdDuty.endMinutesFromMidnight),
      flyingTime: getTimePeriod(thirdDuty.flightDurationInMinutes),
      machineId: thirdDuty.machineId,
    }
  }

  return fdtlForm
}

export const getFdtlDuties = ({
  firstDuty,
  secondDuty,
  thirdDuty,
}: FdtlFormType) => {
  const fdtlDuties: FlightDutyType[] = []

  fdtlDuties.push({
    machineId: firstDuty.machineId,
    sequence: 1,
    startMinutesFromMidnight: getMinutesFromMidnight(firstDuty.dutyStartTime),
    endMinutesFromMidnight: getMinutesFromMidnight(firstDuty.dutyEndTime),
    flightDurationInMinutes: getMinutesFromMidnight(firstDuty.flyingTime),
  })

  if (secondDuty) {
    fdtlDuties.push({
      machineId: secondDuty.machineId,
      sequence: 2,
      startMinutesFromMidnight: getMinutesFromMidnight(
        secondDuty.dutyStartTime
      ),
      endMinutesFromMidnight: getMinutesFromMidnight(secondDuty.dutyEndTime),
      flightDurationInMinutes: getMinutesFromMidnight(secondDuty.flyingTime),
    })
  }

  if (thirdDuty) {
    fdtlDuties.push({
      machineId: thirdDuty.machineId,
      sequence: 3,
      startMinutesFromMidnight: getMinutesFromMidnight(thirdDuty.dutyStartTime),
      endMinutesFromMidnight: getMinutesFromMidnight(thirdDuty.dutyEndTime),
      flightDurationInMinutes: getMinutesFromMidnight(thirdDuty.flyingTime),
    })
  }

  return fdtlDuties
}

export const isMultipleFdtlSearchFormValid = ({
  pilotIds,
  startDate,
  endDate,
}: {
  pilotIds: string[]
  startDate: Moment | null
  endDate: Moment | null
}) => {
  if (!startDate || !startDate.isValid) {
    return {
      isValid: false,
      error: 'Start date is invalid',
    }
  }

  if (!endDate || !endDate.isValid) {
    return {
      isValid: false,
      error: 'End date is invalid',
    }
  }

  if (endDate.valueOf() < startDate.valueOf()) {
    return {
      isValid: false,
      error: 'Start date cannot be later than end date.',
    }
  }

  console.log(moment(startDate).add(6, 'months').format('DD MM YYYY'))
  console.log(endDate.diff(moment(startDate).add(6, 'months'), 'd'))

  if (endDate.diff(moment(startDate).add(6, 'months'), 'day') > 0) {
    return {
      isValid: false,
      error: 'You can fetch only data for 6 months at a time',
    }
  }

  if (pilotIds.length === 0) {
    return {
      isValid: false,
      error: 'Please select a pilot',
    }
  }

  return {
    isValid: true,
    error: '',
  }
}

export const isFdtlSearchFormValid = ({
  pilotId,
  startDate,
  endDate,
}: {
  pilotId: string
  startDate: Moment | null
  endDate: Moment | null
}) => {
  if (!startDate || !startDate.isValid) {
    return {
      isValid: false,
      error: 'Start date is invalid',
    }
  }

  if (!endDate || !endDate.isValid) {
    return {
      isValid: false,
      error: 'End date is invalid',
    }
  }

  if (endDate.valueOf() < startDate.valueOf()) {
    return {
      isValid: false,
      error: 'Start date cannot be later than end date.',
    }
  }

  console.log(moment(startDate).add(6, 'months').format('DD MM YYYY'))
  console.log(endDate.diff(moment(startDate).add(6, 'months'), 'd'))

  if (endDate.diff(moment(startDate).add(6, 'months'), 'day') > 0) {
    return {
      isValid: false,
      error: 'You can fetch only data for 6 months at a time',
    }
  }

  if (!pilotId) {
    return {
      isValid: false,
      error: 'Please select a pilot',
    }
  }

  return {
    isValid: true,
    error: '',
  }
}

export const getFdtlDocumentId = (dateInMs: number, utcOffset: string) => {
  return moment(dateInMs).utcOffset(utcOffset).format('DD-MM-YYYY')
}

export const transformDuty = ({
  dutyEndTime,
  dutyStartTime,
  flyingTime,
  machineId,
}: DutyInputType) => ({
  flyingTime: {
    hours: prependZero(flyingTime.hours),
    minutes: prependZero(flyingTime.minutes),
  },
  dutyEndTime: {
    hours: prependZero(dutyEndTime.hours),
    minutes: prependZero(dutyEndTime.minutes),
  },
  dutyStartTime: {
    hours: prependZero(dutyStartTime.hours),
    minutes: prependZero(dutyStartTime.minutes),
  },
  machineId,
})

export const transformFdtl = (
  fdtls?: PilotWithFdtlType[]
): {
  names: string[]
  fdtls: {
    date: string
    pilotFdtls: FdtlAggregateType[]
    timestamp: Timestamp
  }[]
} => {
  if (!fdtls) {
    return {
      names: [],
      fdtls: [],
    }
  }

  const response = {
    names: [] as string[],
    fdtls: [] as {
      date: string
      pilotFdtls: FdtlAggregateType[]
      timestamp: Timestamp
    }[],
  }

  fdtls.forEach(fdtl => {
    const { pilot, fdtls } = fdtl
    response.names.push(pilot.name)

    fdtls.forEach(({ id: date, aggregate, date: timestamp }) => {
      const index = response.fdtls.findIndex(
        ({ date: existingDate }) => existingDate === date
      )

      if (index === -1) {
        response.fdtls.push({
          date,
          timestamp,
          pilotFdtls: [aggregate],
        })
      } else {
        response.fdtls[index].pilotFdtls.push(aggregate)
      }
    })
  })

  return response
}
