import { SxProps, Theme } from '@mui/material'

export const tableHeaders: {
  colSpan?: number
  sx: SxProps<Theme>
  style: React.CSSProperties
  title: string
}[][] = [
  [
    {
      colSpan: 1,
      sx: {
        border: 'none',
        minWidth: 154,
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        lineHeight: '1.5rem',
      },
      title: 'Date',
    },
    {
      colSpan: 1,
      sx: {
        border: 'none',
        minWidth: 150,
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        lineHeight: '1.5rem',
      },
      title: 'On/Off Duty',
    },
    {
      colSpan: 4,
      sx: {
        border: 'none',
        minWidth: 350,
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        lineHeight: '1.5rem',
      },
      title: 'First Duty',
    },
    {
      colSpan: 4,
      sx: {
        border: 'none',
        minWidth: 350,
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        lineHeight: '1.5rem',
      },
      title: 'Second Duty',
    },
    {
      colSpan: 4,
      sx: {
        border: 'none',
        minWidth: 350,
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        lineHeight: '1.5rem',
      },
      title: 'Third Duty',
    },
    {
      colSpan: 4,
      sx: {
        border: 'none',
        minWidth: 380,
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        lineHeight: '1.5rem',
      },
      title: 'Flight Time',
    },
    {
      colSpan: 4,
      sx: {
        border: 'none',
        minWidth: 380,
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        lineHeight: '1.5rem',
      },
      title: 'Flight Duty Time',
    },
    {
      colSpan: 1,
      sx: {
        border: 'none',
        minWidth: 200,
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        lineHeight: '1.5rem',
      },
      title: 'Created at',
    },
    {
      colSpan: 1,
      sx: {
        border: 'none',
        minWidth: 200,
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        lineHeight: '1.5rem',
      },
      title: 'Created at',
    },
  ],
  [
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        color: 'white',
      },
      title: '-',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        color: 'white',
      },
      title: '-',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'Call Sign',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'Start',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'End',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'Flight Time',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'Call Sign',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'Start',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'End',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'Flight Time',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'Call Sign',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'Start',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'End',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'Flight Time',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'On Date',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: '7 day',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: '28 day',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: '365 day',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: 'On Date',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: '7 day',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: '28 day',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
      },
      title: '365 day',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        color: 'white',
      },
      title: '-',
    },
    {
      sx: {
        border: 'none',
        padding: 0,
      },
      style: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 16px',
        color: 'white',
      },
      title: '-',
    },
  ],
]
