import React from 'react'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'

interface ConfirmModalProps {
  onClose: () => void
  onConfirm: () => void
  title: string
  description: string
  open: boolean
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const ConfirmModal = ({
  onClose,
  onConfirm,
  title,
  description,
  open,
}: ConfirmModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          {title}
        </Typography>
        <Box mt={2}>
          <Typography
            id='modal-modal-title'
            variant='subtitle1'
            component='div'
          >
            {description}
          </Typography>
        </Box>
        <Box mt={2} textAlign='right'>
          <Button variant='outlined' onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button variant='contained' onClick={onConfirm}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmModal
