import React from 'react'
import { Flex } from 'src/styles/Layout'
import classes from './styles.module.css'

const withPageBody = <P extends object>(
  Component: React.ComponentType<P>,
  fullWidth = false
): React.FC<P> => {
  return function WrappedComponent(props: P) {
    return (
      <Flex
        style={{
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 64px)',
          height: 'calc(100vh - 64px)',
          overflowY: 'auto',
          width: '100%',
        }}
        className={classes.pageWrapper}
      >
        <Flex
          style={{
            flexDirection: 'column',
            width: fullWidth ? 'calc(100% - 20px)' : 1024,
            margin: fullWidth ? '0 auto' : '0 30px',
            paddingBottom: 0,
          }}
          className={classes.pageInnerWrapper}
        >
          <Component {...(props as P)} />{' '}
        </Flex>
      </Flex>
    )
  }
}

export default withPageBody
