import React, { useEffect, useMemo, useState } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

type UserType = {
  roles: string[]
  organisationIds: string[]
  email: string
} | null

interface AuthContextType {
  user: UserType
  isLoading: boolean
  setIsLoading: (loading: boolean) => void
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = React.createContext<AuthContextType>({
  user: null,
  isLoading: true,
  setIsLoading: () => null,
})

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = getAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = React.useState<UserType>(null)

  useEffect(() => {
    onAuthStateChanged(
      auth,
      async loggedInUser => {
        let nextUser = null
        if (loggedInUser) {
          const idTokenResult = await loggedInUser?.getIdTokenResult()
          const { claims } = idTokenResult
          const { email } = loggedInUser

          nextUser = {
            organisationIds: claims.organisationIds as string[],
            roles: claims.roles as string[],
            email: email as string,
          }
        }

        setUser(nextUser)
        setIsLoading(false)
      },
      err => {
        console.log(err)
      }
    )
  }, [])

  const value = useMemo(
    () => ({ user, isLoading, setIsLoading }),
    [user, isLoading]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return React.useContext(AuthContext)
}
