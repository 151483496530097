import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { Button } from '@mui/material'

import { createPilotApi } from 'src/api'
import { CreatePilotType, PilotType } from 'src/types/pilot'
import { updatePilotApi } from 'src/api/pilot'

interface CreatePilotModalProps {
  onClose: () => void
  beforeCreate: () => void
  afterCreate: () => void
  pilotForEdit: PilotType | null
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const CreatePilotModal = ({
  onClose,
  beforeCreate,
  afterCreate,
  pilotForEdit,
}: CreatePilotModalProps) => {
  const [name, setName] = useState(pilotForEdit?.name || '')
  const isEdit = !!pilotForEdit

  const onChangeName: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setName(value)
  }

  const onAddPilot: React.MouseEventHandler<HTMLButtonElement> = async () => {
    const trimmedName = name.trim()

    if (trimmedName) {
      beforeCreate()
      try {
        if (isEdit) {
          await updatePilotApi({ name: trimmedName }, pilotForEdit.id)
        } else {
          await createPilotApi({ name: trimmedName })
        }
      } catch (err) {
        console.log(err)
      } finally {
        afterCreate()
      }
    }
  }

  return (
    <Modal
      open
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography
          id='modal-modal-title'
          variant='h6'
          component='h2'
          fontWeight='bold'
        >
          {isEdit ? 'Edit' : 'Add'} Pilot
        </Typography>
        <Box mt={2}>
          <TextField
            fullWidth
            id='outlined-basic'
            label='Pilot Name'
            variant='outlined'
            value={name}
            onChange={onChangeName}
          />
        </Box>
        <Box mt={2} textAlign='right'>
          <Button variant='contained' onClick={onAddPilot}>
            {isEdit ? 'Edit' : 'Add'}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default CreatePilotModal
