import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box } from 'src/styles/Layout'
import { Button, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import withPageBody from 'src/hoc/PageBody'

import { getPilotsApi } from 'src/api'
import { PilotType } from 'src/types/pilot'
import { formatTimestamp } from 'src/utils/moment'
import LoadingOverlay from 'src/components/LoadingOverlay'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import CreatePilotModal from './CreatePilotModal'
import ConfirmModal from 'src/components/ConfirmModal'
import { deletePilotApi } from 'src/api/pilot'

function PilotsPage() {
  const [showAddPilot, setShowAddPilot] = React.useState(false)
  const [pilots, setPilots] = React.useState<PilotType[]>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [selectedPilot, setSelectedPilot] = React.useState<PilotType | null>(
    null
  )
  const [selectedPilotForDelete, setSelectedPilotForDelete] =
    React.useState<PilotType | null>(null)

  const getPilots = async () => {
    setIsLoading(true)

    try {
      const pilots = await getPilotsApi()
      setPilots(pilots.data.pilots as PilotType[])
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deletePilot = async () => {
    if (!selectedPilotForDelete) {
      return
    }

    setIsLoading(true)

    try {
      await deletePilotApi(selectedPilotForDelete.id)
    } catch (err) {
      console.log(err)
    } finally {
      setSelectedPilotForDelete(null)
      setIsLoading(false)
      getPilots()
    }
  }

  React.useEffect(() => {
    getPilots()
  }, [])

  const onCloseAddModal = () => {
    setShowAddPilot(false)
    setSelectedPilot(null)
  }

  const openCreatePilotModal = () => {
    setSelectedPilot(null)
    setShowAddPilot(true)
  }

  const onEditPilot = (pilot: PilotType) => () => {
    setSelectedPilot(pilot)
    setShowAddPilot(true)
  }

  const beforeCreate = () => {
    setIsLoading(true)
  }
  const afterCreate = () => {
    onCloseAddModal()
    getPilots()
  }

  const onDeletePilot = (pilot: PilotType) => () => {
    setSelectedPilotForDelete(pilot)
  }

  const closeDeleteConfirmationModal = () => {
    setSelectedPilotForDelete(null)
  }

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <Box style={{ alignSelf: 'flex-end', marginTop: 20, marginBottom: 10 }}>
        <Button
          variant='outlined'
          startIcon={<Add />}
          onClick={openCreatePilotModal}
        >
          Add Pilot
        </Button>
      </Box>
      <Box style={{ width: '100%' }}>
        <TableContainer component={Paper}>
          <Table aria-label='simple table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight='bold'>Pilot Name</Typography>
                </TableCell>
                <TableCell align='right'>
                  <Typography fontWeight='bold'>Created At</Typography>
                </TableCell>
                <TableCell align='right'>
                  <Typography fontWeight='bold'>Actions</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pilots.map(pilot => (
                <TableRow
                  key={pilot.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    <Typography>{pilot.name}</Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography>{formatTimestamp(pilot.createdAt)}</Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <IconButton
                      color='primary'
                      aria-label='Edit Pilot'
                      component='span'
                      onClick={onEditPilot(pilot)}
                      style={{ padding: 0, marginRight: 10 }}
                      title='Edit Pilot details'
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color='error'
                      aria-label='Delete Pilot'
                      component='span'
                      onClick={onDeletePilot(pilot)}
                      style={{ padding: 0 }}
                      title='Delete Pilot'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showAddPilot && (
          <CreatePilotModal
            onClose={onCloseAddModal}
            beforeCreate={beforeCreate}
            afterCreate={afterCreate}
            pilotForEdit={selectedPilot}
          />
        )}
      </Box>
      <ConfirmModal
        open={!!selectedPilotForDelete}
        onClose={closeDeleteConfirmationModal}
        onConfirm={deletePilot}
        title='Delete Pilot'
        description={`Are you sure you want to delete the pilot ${selectedPilotForDelete?.name} ?`}
      />
    </>
  )
}

export default withPageBody(PilotsPage)
