import axios from 'axios'
import FirebaseAuth from 'src/auth/FirebaseAuth'

axios.interceptors.request.use(async config => {
  if (!config.headers) {
    return config
  }

  config.headers['Authorization'] = `Bearer ${await FirebaseAuth.getToken()}`
  return config
})
