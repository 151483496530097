import { PaginationParamsType } from 'src/types/common'
import { MachineCreateType } from 'src/types/machine'
import axios from './axios'

export const getMachinesApi = (paginationParams?: PaginationParamsType) => {
  let url = '/machines'

  if (paginationParams?.pageNumber && paginationParams.pageSize) {
    const { pageNumber, pageSize } = paginationParams
    url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`
  }
  return axios.get(url)
}

export const createMachineApi = (data: MachineCreateType) =>
  axios.post('/machines', data)

export const editMachineApi = (data: MachineCreateType, machineId: string) =>
  axios.put(`/machines/${machineId}`, data)

export const deleteMachineApi = (machineId: string) =>
  axios.delete(`/machines/${machineId}`)
