import React, { useMemo } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useAuth } from 'src/auth/AuthProvider'
import { RequireAuth } from 'src/auth/RequireAuth'
import {
  LoginPage,
  MachinePage,
  FdtlPage,
  PilotPage,
  NotFoundPage,
  MultipleFdtlsPage,
} from 'src/pages'

const Router = () => {
  const { user } = useAuth()

  const homeRedirectPath = useMemo(() => {
    return user ? '/fdtls' : '/login'
  }, [user])

  return (
    <Routes>
      <Route path='/login' element={<LoginPage />} />
      <Route
        path='/machines'
        element={
          <RequireAuth>
            <MachinePage />
          </RequireAuth>
        }
      />
      <Route
        path='/fdtls'
        element={
          <RequireAuth>
            <FdtlPage />
          </RequireAuth>
        }
      />
      <Route
        path='/collective-fdtls'
        element={
          <RequireAuth>
            <MultipleFdtlsPage />
          </RequireAuth>
        }
      />
      <Route
        path='/pilots'
        element={
          <RequireAuth>
            <PilotPage />
          </RequireAuth>
        }
      />
      <Route path='/' element={<Navigate replace to={homeRedirectPath} />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  )
}

export default Router
