/* eslint-disable indent */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
  Divider,
} from '@mui/material'
import { MachineType } from 'src/types/machine'
import { Moment } from 'moment'
import { PilotType, PilotFdtlType, DutyInputType } from 'src/types/pilot'
import { Flex } from 'src/styles/Layout'
import DatePicker from '@mui/lab/DatePicker'
import { HighlightOff } from '@mui/icons-material'
import {
  areFlightDutiesValid,
  getFdtlDocumentId,
  getFdtlDuties,
  getFdtlDutyInputType,
} from 'src/utils/fdtl'
import { createFdtlApi, getPilotFdtlApi } from 'src/api/pilot'
import getEnv from 'src/env'
import LoadingOverlay from 'src/components/LoadingOverlay'

interface FdtlModalProps {
  onClose: () => void
  beforeCreate: () => void
  afterCreate: () => void
  pilots: PilotType[]
  machines: MachineType[]
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const TimePicker = ({
  label,
  value,
  hoursOnChange,
  minutesOnChange,
  disabled,
}: {
  label: string
  value: { hours: string | null; minutes: string | null }
  hoursOnChange?: React.ChangeEventHandler<HTMLInputElement>
  minutesOnChange?: React.ChangeEventHandler<HTMLInputElement>
  disabled: boolean
}) => {
  return (
    <Box mr={6}>
      <InputLabel id='demo-simple-select-label' variant='standard'>
        {label}
      </InputLabel>
      <Flex style={{ alignItems: 'center', marginTop: 5 }}>
        <Box width={60} mr={2}>
          <input
            maxLength={2}
            style={{
              width: '100%',
              border: 'none',
              outline: 'none',
              borderBottom: '1px solid',
              borderBottomColor: disabled ? 'lightgray' : 'black',
            }}
            value={value.hours || ''}
            onChange={hoursOnChange}
            disabled={disabled}
          />
        </Box>
        :
        <Box width={60} ml={2}>
          <input
            maxLength={2}
            style={{
              width: '100%',
              border: 'none',
              outline: 'none',
              borderBottom: '1px solid',
              borderBottomColor: disabled ? 'lightgray' : 'black',
            }}
            value={value.minutes || ''}
            onChange={minutesOnChange}
            disabled={disabled}
          />
        </Box>
      </Flex>
    </Box>
  )
}

const DUTY_INITIAL_VALUE = {
  dutyStartTime: {
    hours: '',
    minutes: '',
  },
  dutyEndTime: {
    hours: '',
    minutes: '',
  },
  flyingTime: {
    hours: '',
    minutes: '',
  },
  machineId: '',
}

const FdtlModal = ({
  onClose,
  beforeCreate,
  afterCreate,
  pilots,
  machines,
}: FdtlModalProps) => {
  const [date, setDate] = React.useState<Moment | null>(null)
  const [pilotId, setPilotId] = React.useState<string>('')
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [firstDuty, setFirstDuty] = useState<DutyInputType>({
    ...DUTY_INITIAL_VALUE,
  })
  const [secondDuty, setSecondDuty] = useState<DutyInputType | null>(null)
  const [thirdDuty, setThirdDuty] = useState<DutyInputType | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const openDatePicker = () => setIsDatePickerOpen(true)
  const closeDatePicker = () => setIsDatePickerOpen(false)
  const clearDate: React.MouseEventHandler<HTMLSpanElement> = event => {
    event.stopPropagation()
    setDate(null)
  }

  const onChangeDuty = (
    key: 'firstDuty' | 'secondDuty' | 'thirdDuty',
    dutyField: 'dutyStartTime' | 'dutyEndTime' | 'flyingTime',
    timeField: 'hours' | 'minutes'
  ) => {
    if (key === 'firstDuty') {
      return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setFirstDuty(prevValue => {
          const nextValue = { ...prevValue }

          nextValue[dutyField] = {
            ...nextValue[dutyField],
            [timeField]: value,
          }

          return nextValue
        })
      }
    }

    if (key === 'secondDuty') {
      return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setSecondDuty(prevValue => {
          if (!prevValue) {
            return null
          }

          const nextValue = { ...prevValue }

          nextValue[dutyField] = {
            ...nextValue[dutyField],
            [timeField]: value,
          }

          return nextValue
        })
      }
    }

    if (key === 'thirdDuty') {
      return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setThirdDuty(prevValue => {
          if (!prevValue) {
            return null
          }

          const nextValue = { ...prevValue }

          nextValue[dutyField] = {
            ...nextValue[dutyField],
            [timeField]: value,
          }

          return nextValue
        })
      }
    }
  }

  const onChangeMachineId =
    (key: 'firstDuty' | 'secondDuty' | 'thirdDuty') =>
    ({ target: { value } }: SelectChangeEvent<string | null>) => {
      const setFrom = (
        key === 'firstDuty'
          ? setFirstDuty
          : key === 'secondDuty'
          ? setSecondDuty
          : setThirdDuty
      ) as React.Dispatch<React.SetStateAction<DutyInputType | null>>

      setFrom(prevValue => {
        if (!prevValue) {
          return null
        }

        let nextValue = { ...prevValue }

        nextValue = {
          ...nextValue,
          machineId: value || '',
        }

        return nextValue
      })
    }

  const onChangePilotId = ({
    target: { value },
  }: SelectChangeEvent<string | null>) => {
    setPilotId(value || '')
  }

  const onMutateFdtl = async () => {
    const { isValid, error = '' } = areFlightDutiesValid({
      firstDuty,
      secondDuty,
      thirdDuty,
    })

    if (!date) {
      setErrorMessage('Please select a Date')
      return
    }

    if (!pilotId) {
      setErrorMessage('Please select a Pilot')
      return
    }

    setErrorMessage(error)

    if (isValid) {
      const fdtlDuties = getFdtlDuties({ firstDuty, secondDuty, thirdDuty })

      try {
        beforeCreate()
        const dateInMs = date
          .utcOffset(getEnv()?.utcOffset)
          .set('hours', 0)
          .set('minutes', 0)
          .set('seconds', 0)
          .set('milliseconds', 0)
          .valueOf()

        await createFdtlApi(pilotId, {
          duty: fdtlDuties,
          dateInMs,
        })
        onClose()
      } catch (err) {
        console.log(err)
      } finally {
        afterCreate()
      }
    }
    return
  }

  const fetchPilotFdtl = useCallback(async () => {
    if (!date || !pilotId) {
      return
    }

    try {
      const env = getEnv()
      setIsLoading(true)
      const fdtlId = getFdtlDocumentId(date.valueOf(), env.utcOffset)
      const { fdtl } = (await getPilotFdtlApi({ pilotId, fdtlId })) as {
        fdtl: PilotFdtlType | null
      }
      const duties = getFdtlDutyInputType(fdtl?.duty || [])
      setFirstDuty(duties.firstDuty)
      setSecondDuty(duties.secondDuty)
      setThirdDuty(duties.thirdDuty)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }, [pilotId, date])

  const toggleEnableSecondDuty = () => {
    setSecondDuty(prev => (prev ? null : { ...DUTY_INITIAL_VALUE }))
  }

  const toggleEnableThirdDuty = () => {
    setThirdDuty(prev => (prev ? null : { ...DUTY_INITIAL_VALUE }))
  }

  const isFieldDisabled = useMemo(
    () => !date || !pilotId || isLoading,
    [isLoading, pilotId, date]
  )

  useEffect(() => {
    fetchPilotFdtl()
  }, [fetchPilotFdtl])

  return (
    <Modal
      open
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <>
        {isLoading && <LoadingOverlay />}
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            mb={2}
            fontWeight='bold'
          >
            Add FDTL
          </Typography>
          <Flex style={{ marginTop: 2 }}>
            <DatePicker
              disableFuture
              inputFormat='DD MMMM YYYY'
              label='Duty Date'
              views={['month', 'day']}
              value={date}
              onChange={setDate}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='standard'
                  placeholder='Select Date'
                  sx={{
                    caretColor: 'transparent',
                    cursor: 'pointer',
                    marginRight: 2,
                  }}
                  onClick={openDatePicker}
                  onKeyPress={event => event.preventDefault()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <IconButton
                          color='secondary'
                          aria-label='upload picture'
                          component='span'
                          onClick={clearDate}
                        >
                          <HighlightOff />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              open={isDatePickerOpen}
              onOpen={openDatePicker}
              onClose={closeDatePicker}
            />
            <Box width={150} mr={2}>
              <FormControl fullWidth variant='standard'>
                <InputLabel id='demo-simple-select-label'>Pilot</InputLabel>
                <Select
                  value={pilotId}
                  label='Pilot'
                  onChange={onChangePilotId}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                >
                  {pilots.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Flex>

          {!isFieldDisabled && (
            <>
              <Box>
                <Typography
                  id='modal-modal-title'
                  variant='body1'
                  component='h2'
                  fontWeight='bold'
                  mt={4}
                  mb={1}
                  sx={{ textDecoration: 'underline' }}
                >
                  First Duty
                </Typography>
                <Flex style={{ flexWrap: 'wrap' }}>
                  <Box width='100%' mb={2}>
                    <InputLabel id='demo-simple-select-label'>
                      Aircraft
                    </InputLabel>
                    <Select
                      disabled={isFieldDisabled}
                      value={firstDuty.machineId}
                      label='Machine'
                      onChange={onChangeMachineId('firstDuty')}
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      variant='standard'
                      style={{ width: '25%' }}
                    >
                      {machines.map(({ callSign, id }) => (
                        <MenuItem key={id} value={id}>
                          {callSign}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <TimePicker
                    label='Duty Start Time'
                    value={firstDuty.dutyStartTime}
                    hoursOnChange={onChangeDuty(
                      'firstDuty',
                      'dutyStartTime',
                      'hours'
                    )}
                    minutesOnChange={onChangeDuty(
                      'firstDuty',
                      'dutyStartTime',
                      'minutes'
                    )}
                    disabled={isFieldDisabled}
                  />
                  <TimePicker
                    label='Duty End Time'
                    value={firstDuty.dutyEndTime}
                    hoursOnChange={onChangeDuty(
                      'firstDuty',
                      'dutyEndTime',
                      'hours'
                    )}
                    minutesOnChange={onChangeDuty(
                      'firstDuty',
                      'dutyEndTime',
                      'minutes'
                    )}
                    disabled={isFieldDisabled}
                  />
                  <TimePicker
                    label='Flight Time'
                    value={firstDuty.flyingTime}
                    hoursOnChange={onChangeDuty(
                      'firstDuty',
                      'flyingTime',
                      'hours'
                    )}
                    minutesOnChange={onChangeDuty(
                      'firstDuty',
                      'flyingTime',
                      'minutes'
                    )}
                    disabled={isFieldDisabled}
                  />
                </Flex>
              </Box>
              <Box style={{ marginTop: 20 }}>
                <Divider orientation='horizontal' />
              </Box>
              {!secondDuty && (
                <Box style={{ marginTop: 10 }}>
                  <Button
                    variant='outlined'
                    onClick={toggleEnableSecondDuty}
                    disabled={isFieldDisabled}
                  >
                    Add Second duty
                  </Button>
                </Box>
              )}
              {!!secondDuty && (
                <Box mb={2}>
                  <Flex
                    style={{
                      marginTop: 20,
                      marginBottom: 10,
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      id='modal-modal-title'
                      variant='body1'
                      component='h2'
                      fontWeight='bold'
                      sx={{ textDecoration: 'underline' }}
                    >
                      Second Duty
                    </Typography>
                    <Button
                      variant='outlined'
                      size='small'
                      color='error'
                      onClick={toggleEnableSecondDuty}
                      disabled={isFieldDisabled || !!thirdDuty}
                      sx={{ ml: 1 }}
                    >
                      Remove
                    </Button>
                  </Flex>
                  <Flex style={{ flexWrap: 'wrap' }}>
                    <Box width='100%' mb={2}>
                      <InputLabel id='demo-simple-select-label'>
                        Aircraft
                      </InputLabel>
                      <Select
                        disabled={isFieldDisabled}
                        value={secondDuty.machineId}
                        label='Machine'
                        onChange={onChangeMachineId('secondDuty')}
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        variant='standard'
                        style={{ width: '25%' }}
                      >
                        {machines.map(({ callSign, id }) => (
                          <MenuItem key={id} value={id}>
                            {callSign}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <TimePicker
                      label='Duty Start Time'
                      value={secondDuty.dutyStartTime}
                      hoursOnChange={onChangeDuty(
                        'secondDuty',
                        'dutyStartTime',
                        'hours'
                      )}
                      minutesOnChange={onChangeDuty(
                        'secondDuty',
                        'dutyStartTime',
                        'minutes'
                      )}
                      disabled={isFieldDisabled}
                    />
                    <TimePicker
                      label='Duty End Time'
                      value={secondDuty.dutyEndTime}
                      hoursOnChange={onChangeDuty(
                        'secondDuty',
                        'dutyEndTime',
                        'hours'
                      )}
                      minutesOnChange={onChangeDuty(
                        'secondDuty',
                        'dutyEndTime',
                        'minutes'
                      )}
                      disabled={isFieldDisabled}
                    />
                    <TimePicker
                      label='Flying Time'
                      value={secondDuty.flyingTime}
                      hoursOnChange={onChangeDuty(
                        'secondDuty',
                        'flyingTime',
                        'hours'
                      )}
                      minutesOnChange={onChangeDuty(
                        'secondDuty',
                        'flyingTime',
                        'minutes'
                      )}
                      disabled={isFieldDisabled}
                    />
                  </Flex>
                </Box>
              )}

              {!!secondDuty && !thirdDuty && (
                <Box style={{ marginTop: 10 }}>
                  <Button
                    variant='outlined'
                    onClick={toggleEnableThirdDuty}
                    disabled={isFieldDisabled}
                  >
                    Add Third duty
                  </Button>
                </Box>
              )}

              {!!thirdDuty && (
                <Box mb={2}>
                  <Flex
                    style={{
                      marginTop: 20,
                      marginBottom: 10,
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      id='modal-modal-title'
                      variant='body1'
                      component='h2'
                      fontWeight='bold'
                      sx={{ textDecoration: 'underline' }}
                    >
                      Third Duty
                    </Typography>
                    <Button
                      variant='outlined'
                      size='small'
                      color='error'
                      onClick={toggleEnableThirdDuty}
                      disabled={isFieldDisabled}
                      sx={{ ml: 1 }}
                    >
                      Remove
                    </Button>
                  </Flex>
                  <Flex style={{ flexWrap: 'wrap' }}>
                    <Box width='100%' mb={2}>
                      <InputLabel id='demo-simple-select-label'>
                        Aircraft
                      </InputLabel>
                      <Select
                        disabled={isFieldDisabled}
                        value={thirdDuty.machineId}
                        label='Machine'
                        onChange={onChangeMachineId('thirdDuty')}
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        variant='standard'
                        style={{ width: '25%' }}
                      >
                        {machines.map(({ callSign, id }) => (
                          <MenuItem key={id} value={id}>
                            {callSign}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <TimePicker
                      label='Duty Start Time'
                      value={thirdDuty.dutyStartTime}
                      hoursOnChange={onChangeDuty(
                        'thirdDuty',
                        'dutyStartTime',
                        'hours'
                      )}
                      minutesOnChange={onChangeDuty(
                        'thirdDuty',
                        'dutyStartTime',
                        'minutes'
                      )}
                      disabled={isFieldDisabled}
                    />
                    <TimePicker
                      label='Duty End Time'
                      value={thirdDuty.dutyEndTime}
                      hoursOnChange={onChangeDuty(
                        'thirdDuty',
                        'dutyEndTime',
                        'hours'
                      )}
                      minutesOnChange={onChangeDuty(
                        'thirdDuty',
                        'dutyEndTime',
                        'minutes'
                      )}
                      disabled={isFieldDisabled}
                    />
                    <TimePicker
                      label='Flying Time'
                      value={thirdDuty.flyingTime}
                      hoursOnChange={onChangeDuty(
                        'thirdDuty',
                        'flyingTime',
                        'hours'
                      )}
                      minutesOnChange={onChangeDuty(
                        'thirdDuty',
                        'flyingTime',
                        'minutes'
                      )}
                      disabled={isFieldDisabled}
                    />
                  </Flex>
                </Box>
              )}

              {errorMessage ? (
                <Alert variant='outlined' severity='error'>
                  {errorMessage}
                </Alert>
              ) : (
                <Box p={2} />
              )}
              <Box mt={2} textAlign='right'>
                <Button
                  variant='contained'
                  onClick={onMutateFdtl}
                  disabled={isFieldDisabled}
                >
                  Add
                </Button>
              </Box>
            </>
          )}
        </Box>
      </>
    </Modal>
  )
}

export default FdtlModal
