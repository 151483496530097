export function downloadCSV(csv: string, filename: string) {
  const csvFile = new Blob([csv], { type: 'text/csv' })
  const downloadLink = document.createElement('a')

  // Set the file name
  downloadLink.download = filename

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile)

  // Hide the download link
  downloadLink.style.display = 'none'

  // Add the link to the DOM
  document.body.appendChild(downloadLink)

  // Click the download link
  downloadLink.click()
}
