import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'

class FirebaseAuth {
  signInWithEmail(email: string, password: string) {
    return signInWithEmailAndPassword(getAuth(), email, password)
  }

  signOut() {
    return signOut(getAuth())
  }

  getToken() {
    return getAuth().currentUser?.getIdToken()
  }
}

export default new FirebaseAuth()
