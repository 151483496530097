import devEnv from 'src/env/dev.json'
import prodEnv from 'src/env/production.json'

const getEnv = () => {
  if (process.env.REACT_APP_ENV === 'dev') {
    return devEnv
  }

  if (process.env.REACT_APP_ENV === 'production') {
    return prodEnv
  }

  throw new Error('INVALID REACT_APP_ENV VALUE')
}

export default getEnv
