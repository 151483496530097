import { PaginationParamsType } from 'src/types/common'
import { CreatePilotType, PilotFdtlRequestType } from 'src/types/pilot'
import axios from './axios'

export const getPilotsApi = (paginationParams?: PaginationParamsType) => {
  let url = '/pilots'

  if (paginationParams?.pageNumber && paginationParams.pageSize) {
    const { pageNumber, pageSize } = paginationParams
    url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`
  }
  return axios.get(url)
}

export const createPilotApi = (data: CreatePilotType) =>
  axios.post('/pilots', data)

export const updatePilotApi = (data: CreatePilotType, pilotId: string) =>
  axios.put(`/pilots/${pilotId}`, data)

export const deletePilotApi = (pilotId: string) =>
  axios.delete(`/pilots/${pilotId}`)

export const getPilotFdtlListApi = ({
  pilotId,
  startDateInMs,
  endDateInMs,
}: {
  pilotId: string
  startDateInMs: number
  endDateInMs: number
}) =>
  axios.get(
    `/pilots/${pilotId}/fdtl?startDateInMs=${startDateInMs}&endDateInMs=${endDateInMs}`
  )

export const getPilotsFdtlListApi = ({
  pilotIds,
  startDateInMs,
  endDateInMs,
}: {
  pilotIds: string[]
  startDateInMs: number
  endDateInMs: number
}) => {
  return Promise.all(
    pilotIds.map(pilotId =>
      axios.get(
        `/pilots/${pilotId}/fdtl?startDateInMs=${startDateInMs}&endDateInMs=${endDateInMs}`
      )
    )
  ).then(responses => responses.map(response => response.data))
}

export const createFdtlApi = (pilotId: string, data: PilotFdtlRequestType) =>
  axios.post(`/pilots/${pilotId}/fdtl`, data)

export const getPilotFdtlApi = async ({
  pilotId,
  fdtlId,
}: {
  pilotId: string
  fdtlId: string
}) => (await axios.get(`/pilots/${pilotId}/fdtl/${fdtlId}`)).data

export const deleteFdtlApi = async ({
  pilotId,
  fdtlId,
}: {
  pilotId: string
  fdtlId: string
}) => await axios.delete(`/pilots/${pilotId}/fdtl/${fdtlId}`)
