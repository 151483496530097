import { TimestampOrNull, Timestamp } from './common'

export type MachineType = {
  callSign: string
  type: MachineTypeEnum
  modelNo: string
  deletedAt: TimestampOrNull
  createdAt: Timestamp
  id: string
}

export enum MachineTypeEnum {
  'HELICOPTER' = 'HELICOPTER',
  'AIRCRAFT' = 'AIRCRAFT',
}

export type MachineCreateType = {
  callSign: string
  type: MachineTypeEnum
  modelNo: string
}
